body {

}
.h-full {
	min-height: 550px;
	height: 100vh;
	@include media-breakpoint-up(md) {min-height: 100%;}
}

.home .h-full {
	min-height: 650px;
}

.bg-img {
	background-repeat: no-repeat; background-position: center center; background-size: cover;
}

.bg-gradient {
	@include gradient-x-three-colors($start-color: $primary, $mid-color: $info, $color-stop: 50%, $end-color: $primary)
}
.overlay {
	position: absolute;
	top: 0;
	bottom:0;
	height: 100%;
	width: 100%;
	background-color: rgba(black,.4);
}
h1, h2, h3 {
	letter-spacing: .05rem;
	line-height: 1.3em;
	text-transform: uppercase;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2rem; margin-bottom: 1.5rem;
}

h3 {
	font-size: 1.5rem;
}

b, strong {
	font-weight: 700;
}

.page-header {
	border-bottom: 1px solid #ddd;
}

.btn {
	padding: .75rem 2rem;
	font-weight: 400;
}

.btn-grey {
	@include button-variant(#DDD, #CCC);
}

.section {
	position: relative;
	.bg-colour {
		height: 100%;
		width: 100%;
		background-color: rgba(black,.3);
		h2 {color: white;}
	}
}

.backtotop {
		position: absolute;
		left: 50%;
		bottom: 0;
		z-index: 3;
		padding: 0;
		overflow: hidden;
		cursor: pointer;
		text-align: center;
		line-height: 0;
		-webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg);
		.scroll-icon {
			width: 48px;
			height: 23px;
			margin-bottom: 2vh;
			fill: transparent;
			stroke: $primary;
			stroke-width: 2;
			-webkit-transition: transform 170ms ease-out, opacity 170ms ease-out; -moz-transition: transform 170ms ease-out, opacity 170ms ease-out;
			-ms-transition: transform 170ms ease-out, opacity 170ms ease-out; transition: transform 170ms ease-out, opacity 170ms ease-out;
			&:hover {
				-webkit-transform: translatey(.5vh);
				-moz-transform: translatey(.5vh);
				-ms-transform: translatey(.5vh);
				-o-transform: translatey(.5vh);
				transform: translatey(.5vh);
			}
		}
	}