.newsroom {
	.news-img-wrap {
		height: 350px;
		transition: background-image 0.7s ease;
		overflow: hidden;
		@include media-breakpoint-up(lg) { height: 270px;}
		@include media-breakpoint-up(xl) { height: 350px;}
		.news-img {
			height: 100%;
			width: 100%;
			transition: all 0.7s ease;
			&:hover {
				transform: scale(1.2);
			}
		}
		.news-text {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			background: rgba(0, 0, 0, 0.75);
			padding: 20px 23px;
			letter-spacing: 1px;
			color: #fff;
			z-index: 100;
			opacity: 0;
			transition: all 0.7s ease;
			h4 {
				font-size: 1.5rem;
				a {color: #FFF;}
			}
		}
		&:hover {
			.news-text {opacity: 1;}
		}
	}
	h4 {
		font-size: 1.2rem; font-weight: 400;
		@include media-breakpoint-up(lg) {}
	}
	a:hover {text-decoration: none;}
}

.posts-navigation {
	.nav-links {display: block; margin-bottom: 30px;}
	.nav-previous {float: left;}
	.nav-next {float: right;}
	.nav-previous, .nav-next {padding: 5px 10px; border: 1px solid $primary; background-color: $primary; color: white; border-radius: 10px;}
	&:after {display: block; content: ""; clear: both; margin-bottom: 30px;}
}

.swiper-container {
	width: 100%;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
}

.news-swiper {
	.swiper-slide {
		h4 {
			font-size: 1.2rem; font-weight: 400;
			@include media-breakpoint-up(lg) {}
		}
		a:hover {text-decoration: none;}
	}
}

.swiper-button-next, .swiper-button-prev {
	top:0;
	margin-top: 0;
	width: 20px;
	height: 25px;
	background-size: 20px 25px;
}

 .swiper-button-prev {
	right: 50px;
	left: auto;
}

 .swiper-button-next {
	right: 20px;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 100%;
	background: #FFF;
	opacity: 0.2;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #FFF;
}