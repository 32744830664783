.content-info {
	font-size: .9rem;
	a {
		color: rgba(228,228,228,.8);
		&:hover {
			color: rgba(228,228,228,1);
		}
	}
	&.footer-bg {
		position: relative;
	}
	.footer-overlay {
		height: 100%;
		width: 100%;
		background-color: rgba($secondary,.5);
	}
}

.news-bg {
	position: relative;
	.news-bg-overlay {
		height: 100%;
		width: 100%;
		background-color: rgba(black,.5);
	}
}