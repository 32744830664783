.banner {
	.brand {
		width: 270px;
		@include media-breakpoint-up(sm) {width: 350px;}
		@include media-breakpoint-up(md) {width: 400px;}
	}
	.navbar-nav {
		.nav-item {
			.nav-link {
				font-weight: 400;
				font-style: normal;
				font-size: .8rem;
				letter-spacing: .2em;
				text-transform: uppercase;
				@include media-breakpoint-up(lg) {padding-left: 1rem; padding-right: 1rem; letter-spacing: .05em;}
				@include media-breakpoint-up(xl) {letter-spacing: .2em;}
			}
		}
	}
	.page-scroll {
		position: absolute;
		bottom: 3%;
		left: 50%;
		z-index: 3;
		padding: 0 10px;
		overflow: hidden;
		cursor: pointer;
		text-align: center;
		line-height: 0;
		-webkit-transform: translatex(-50%); -moz-transform: translatex(-50%); -ms-transform: translatex(-50%); transform: translatex(-50%);
		.scroll-icon {
			width: 48px;
			height: 23px;
			margin-bottom: 2vh;
			fill: transparent;
			stroke: #fff;
			stroke-width: 2;
			-webkit-transition: transform 170ms ease-out, opacity 170ms ease-out; -moz-transition: transform 170ms ease-out, opacity 170ms ease-out;
			-ms-transition: transform 170ms ease-out, opacity 170ms ease-out; transition: transform 170ms ease-out, opacity 170ms ease-out;
			&:hover {
				-webkit-transform: translatey(.5vh);
				-moz-transform: translatey(.5vh);
				-ms-transform: translatey(.5vh);
				-o-transform: translatey(.5vh);
				transform: translatey(.5vh);
			}
		}
	}
}

.video-header, .photo-header {
	h1 {
		line-height: 1.2em;
		font-size: 2rem;
		@include media-breakpoint-up(sm) {font-size: 2.75rem;}
		small {
			font-weight: 400;
			font-size: 1.5rem;
			line-height: 2em;
		}
	}
	.summary {
		font-weight: 300;
		font-size: 1.1rem;
		letter-spacing: .1rem;
		line-height: 1.5;
		@include media-breakpoint-up(sm) {line-height: 2em; }
	}
}