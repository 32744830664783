// Grid settings
$main-sm-columns:    	   			12;
$sidebar-sm-columns:	    		4;

$primary:									#2D2377;
$secondary:								#1D0E46;
$info:										#598EC3;
$dark:										#050400;
$body-color:								rgba(66,66,66,.65);
$headings-color:						black;

$headings-font-family:				'Sorts Mill Goudy', sans-serif;
$font-family-base:						'Raleway', sans-serif;
$font-weight-base:						300;
$headings-font-weight:				700;
$headings-color:						$primary;

$border-radius:							0;
$btn-border-radius-lg:				25px;
$btn-border-radius:					25px;

// Nav
$navbar-dark-color:					rgba(white,.9);
$navbar-dark-hover-color:			white;
$navbar-dark-active-color:			white;